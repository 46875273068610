@import "./assets/css/ant.css";

.App {
  /* overflow-x: hidden; */
  /* overflow-y: hidden;  */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-input {
  background-color: transparent !important;
  color: #000000 !important;
}

.App-header {
  background-color: #ffffff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 90px;
}

.page-header {
  left: 0px;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px;
}

.body-content {
  padding: 0% 0%;
  /* height: calc(100% - 45px); */
  height: 100%;
  overflow: auto !important;
}

.body-content-x {
  padding: 0% 0%;
  height: calc(100% - 45px);
  overflow: auto !important;
}

.register-page,
.reset-password-page {
  /* font-family: "Oswald", sans-serif !important; */
  display: flex;
  flex-direction: column;
  padding: 15px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  height: 100%;
}

.verify-page,
.forget-password-page {
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.forget-password-page-form-box {
  background-color: #ffffff;
  border: 1.5px solid #000000;
  padding: 3%;
  border-radius: 10px;
  width: 35%;
}

.reset-password-page-form-box {
  background-color: #ffffff;
  border: 1.5px solid #000000;
  padding: 2%;
  border-radius: 10px;
  width: 35%;
}

.form-box {
  /* font-family: "Oswald", sans-serif !important; */
  background-color: #ffffff;
  border: 1.5px solid #000000;
  padding: 3%;
  border-radius: 10px;
  width: 40%;
}

.page-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  height: 10%;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.NL-App-header {
  background-color: #142e2e !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-acq {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  background: url("./assets/img/header.png") no-repeat;
  background-size: 100%;
  color: white;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  flex-direction: column;
  text-align: center;
}

.header-acq-register {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  background: url("./assets/img/header.png") no-repeat;
  background-size: 100%;
  color: white;
  display: grid;
  flex-direction: column;
  text-align: center;
}

.header-acq-login {
  width: 65vw;
  height: 80vh;
  position: fixed;
  left: 10vw;
  background: url("./assets/img/header.png") no-repeat;
  background-size: 100% 100%;
  color: white;
}

.steps-content {
  min-height: 200px;
  /* height: auto; */
  width: 90%;
  margin-top: 16px;
  padding-top: 20px;
  margin: auto;
  text-align: center;
  background-color: #ffffff;
  /* border: 1px dashed #e9e9e9; */
  border-radius: 2px;
}

.steps-action {
  padding-left: 7.5%;
  margin: auto;
  margin-bottom: 1%;
  /* margin-top: 24px; */
  /* padding-left: 5%; */
  /* margin-bottom: 20%; */
  /* bottom: 0; */
  /* width: 100%; */
  /* position: absolute; */
  /* margin: auto; */
}

.steps-action-1 {
  /* margin-top: 24px; */
  /* border: 1px solid red; */
  padding-left: 7.5%;
  margin: auto;
  margin-bottom: 5%;
  /* display: flex;
  flex-wrap: wrap;
  max-width: 300px; */
  /* padding-bottom: 10%; */
  /* bottom: 0; */
  /* width: 100%; */
  /* position: absolute; */
  /* margin: auto; */
}

.config_gui_setup {
  width: 90%;
  margin: auto;
  padding-top: 30px;
}

.translate-en,
.translate-en:focus,
.translate-en:hover,
.translate-en:active {
  background-image: url("./assets/img/united-kingdom.png") !important;
  background-size: cover;
}

.translate-cn,
.translate-cn:focus,
.translate-cn:hover,
.translate-cn:active {
  background-image: url("./assets/img/china.png") !important;
  background-size: cover;
}

.translate-ja,
.translate-ja:focus,
.translate-ja:hover,
.translate-ja:active {
  background-image: url("./assets/img/japan.png") !important;
  background-size: cover;
}

.translate-de,
.translate-de:focus,
.translate-de:hover,
.translate-de:active {
  background-image: url("./assets/img/germany.png") !important;
  background-size: cover;
}

.input-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-input-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-input-form > input,
.company-input-form > p,
.company-input-form > .ant-select,
.company-input-form > .ant-input-password {
  width: 75% !important;
}

.label-form {
  margin-left: 50px;
  font-weight: 500;
  text-align: left;
}

.input-label-form {
  /* margin-left: 50px; */
  font-weight: 500;
  text-align: left;
  /* margin-bottom: 10px; */
}

.ref-input-form {
  display: flex;
  flex-direction: column; /* Display label and input in a column layout */
  align-items: flex-start; /* Align items to the start (top) of the column */
}

.ref-input-label-form {
  margin-bottom: 5px; /* Add some space between the label and input */
}

.input-field-container {
  width: 100%;
}

.input-form > input,
.input-form > p,
.input-form > .ant-select,
.input-form > .ant-input-password {
  width: 70% !important;
}

.input-form > p {
  margin: 0px;
  height: 32px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  .input-form > input,
  .input-form > p,
  .input-form > .ant-select,
  .input-form > .ant-input-password {
    width: 70% !important;
  }

  .label-form {
    margin-bottom: 10px;
  }
}

/* .input-form > p {
  margin: 0px;
  height: 32px;
  display: flex;
  align-items: center;
} */

@media (max-width: 1270px) {
  .header-acq-login {
    display: none;
  }

  .NL-App-header {
    background-color: #142e2e !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.header-main {
  font-size: 45px;
  font-weight: 600;
  padding-top: 80px;
}

.header-main-login {
  font-size: 45px;
  font-weight: 600;
  background: url("./assets/img/acquizdata_name_logo.png") no-repeat;
  background-size: 100% 100%;
  width: 40vw;
  height: 20vh;
  top: 35%;
  left: 10%;
  position: relative;
  /* padding-top: 50px;  */
}

.header-main-login-address {
  font-size: calc(3px + 0.5vw);
  font-weight: 400;
  /* width:40vw;
  height: 20vh; */
  top: 65%;
  left: 12%;
  position: relative;
}

.header-main-login-title {
  font-size: calc(3px + 1vw);
  font-weight: 700;
  /* width:40vw;
  height: 20vh; */
  top: 35%;
  left: 40%;
  position: relative;
}

.App-footer {
  background-color: rgba(7, 52, 42, 0.9);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  color: white;
  width: 100%;
  height: 25px;
  bottom: 0;
}

.ant-checkbox-wrapper span:not(.ant-checkbox) {
  padding-left: 16px;
}

.regionmodel {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  z-index: 500;
}

.regionmodel-content {
  width: 40vw;
  height: 40vh;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 4px;
}

/* .modal-class .ant-modal-header {
  background: #d60f0f;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.searchResult {
  width: 90vw;
  min-height: 10vh;
  margin: auto;
  /* overflow:auto; */
  border: 1px solid rgba(0, 0, 0, 0.6);
  /* padding:0 0 0px 0px; */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cecece;
}
