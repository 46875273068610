.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #000000 !important;
  background: #66cdaa !important;
  border-color: #66cdaa !important;
  font-weight: bold !important;
}

/* .custom {
  color: #000000 !important;
  background: #66cdaa !important;
  border-color: #66cdaa !important;
  font-weight: bold;
} */

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  font-weight: bold;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
  text-shadow: 0 0 0.25px currentcolor;
}
