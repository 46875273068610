.login-box {
  background-color: #ffffff;
  border: 2px solid #bdbcbb1f;
  min-height: 450px;
  z-index: 999;
  margin-top: 20px;
  margin: auto;
  min-width: 500px;
  display: grid;
  border-radius: 15px;
  position: fixed;
  left: 60%;
  right: 35%;
  color: #000000;
  display: flex;
  flex-direction: column;
  padding: 15px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
}

.login-title {
  padding-top: 40px;
  color: black;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

.login-message {
  color: black;
  font-size: 18px;
  line-height: 20px;
  transition: 0.3s;
  width: 100%;
  margin-left: 10%;
}

.button-green {
  background-color: #3d9b70;
  color: #ffffff;
  border-color: #d9d9d9;
}

.button-green:hover,
.button-green:active {
  background-color: #ffffff;
  color: #3d9b70;
  border-color: #3d9b70;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.button-link {
  color: #4caf50;
  flex: 1 1 auto;
}

.button-register-link {
  color: rgba(36, 169, 169, 0.74);
  flex: 1 1 auto;
}

.button-forgot-password-link {
  color: rgb(0, 0, 0);
  flex: 1 1 auto;
}

.button-link:hover,
.button-link:focus {
  color: #235b24;
}

.button-bar {
  height: 18px;
  border-left: 1px solid #000000;
}

@media (max-width: 1270px) {
  .login-box {
    background-color: #ffffff;
    border: 2px solid #bdbcbb1f;
    min-height: 450px;
    z-index: 999;
    margin-top: 20px;
    margin: auto;
    max-width: 500px;
    display: grid;
    /* grid-template-rows: 1fr 1fr ; */
    border-radius: 15px;
    position: fixed;
    left: 0;
    /* top: 25%; */
    right: 0;
    /* bottom: 1; */
    color: #000000;
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
