.open-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.open-modal-content {
  width: 70vw;
  height: 60vh;
  background-color: #ffffff;
  z-index: 999;
  border-radius: 4px;
}

.open-modal-header,
.open-modal-footer {
  padding: 10px;
}

.open-modal-title {
  margin: 0;
}
.company-form .ant-form-item-label > label {
  font-size: 13px;
  font-weight: 600;
  /* position: relative;
  display: inline-flex;
  align-items: right; */
}

/* .company-form .ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.company-form .ant-col-push-12 {
  left: 30%;
}
.company-form .ant-col-pull-12 {
  right: 50%;
}
.company-form .ant-col-offset-12 {
  margin-left: 50%;
} */

.open-modal-body {
  padding: 10px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.open-modal-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.open-modal-label {
  padding-top: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
}

@media (max-width: 665px) {
  .open-modal-label {
    padding-top: 8px;
    display: flex;
    font-size: 12px !important;
    font-weight: 700;
  }
}

.open-modal-button {
  background-color: #ffffff;
  color: #000000;
  min-width: 25%;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(172, 172, 172);
  margin: 0 30px 0 30px;
  font-size: 14px;
}

.open-modal-footer-sp {
  /* margin: auto; */
  display: grid;
  grid-template-columns: auto auto;
}

.open-modal-save-button {
  background-color: rgba(21, 161, 69, 1);
  color: white;
  min-width: 100px;
  border: 0px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 6px;
  /* box-shadow: 0px 0px 2px 2px rgb(172, 172, 172); */
  margin: auto 0 auto 0;
  font-size: 14px;
  cursor: pointer;
}

.open-modal-save-button:hover {
  background-color: #3e8e41;
}

.open-modal-cancel-button {
  background-color: transparent;
  color: rgba(68, 108, 185, 1);
  /* min-width: 10%; */
  border: 0px solid rgb(0, 0, 0);
  /* box-shadow: 0px 0px 2px 2px rgb(172, 172, 172); */
  margin: 30px 10px 30px 0;
  font-size: 14px;
  text-decoration: underline;

  cursor: pointer;
}

.open-modal-reset-button {
  background-color: transparent;
  color: rgba(68, 108, 185, 1);
  /* min-width: 10%; */
  border: 0px solid rgb(0, 0, 0);
  /* box-shadow: 0px 0px 2px 2px rgb(172, 172, 172); */
  margin: 30px 0 30px 0;
  font-size: 14px;
  text-decoration: underline;
  text-decoration-color: rgba(68, 108, 185, 1);
  cursor: pointer;
}

.open-modal-link-button {
  background-color: transparent;
  color: rgba(68, 108, 185, 1);
  /* min-width: 10%; */
  border: 0px solid rgb(0, 0, 0);
  /* box-shadow: 0px 0px 2px 2px rgb(172, 172, 172); */
  font-size: 14px;
  text-decoration: underline;
  text-decoration-color: rgba(68, 108, 185, 1);
  cursor: pointer;
}

.open-modal-close-button {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  min-width: 5%;
  border: 0px solid rgb(0, 0, 0);
  border-radius: 0px;
  /* box-shadow: 0px 0px 2px 2px rgb(172, 172, 172); */
  margin: 0 30px 0 30px;
  font-size: 14px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.open-3modal-button {
  background-color: #ffffff;
  color: #000000;
  min-width: 25%;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(172, 172, 172);
  margin: 0 10px 0 10px;
  font-size: 14px;
}

.open-modal-button:hover {
  outline-color: transparent;
  outline-style: solid;
  background-color: #c5c5c5;
  box-shadow: 0 0 0 2px #8d8d8d;
}

.abbreviation-type-list-total {
  display: flex;
  font-size: 14px;
  text-align: center;
  padding: 2px;
  padding-left: 5px;
}

.abbreviation-amount-list-total {
  display: flex;
  font-size: 14px;
  text-align: center;
  padding: 2px;
  justify-content: center;
}
