.alert-box {
  background-color: #ffffff;
  border: 2px solid #bbbdbd;
  height: auto;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  margin-top: 10px;
  width: 30%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  border-radius: 15px;
  position: fixed;
  left: 35%;
  top: 5%;
  right: 35%;
  bottom: 1;
  color: #000000;
}

.alert-title {
  padding-top: 15px;
  color: black;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.alert-message {
  color: black;
  float: right;
  font-size: 18px;
  line-height: 20px;
  transition: 0.3s;
  text-align: center;
  padding: 0px 30px;
  padding-bottom: 20px;
  white-space: pre-line;
}
