.confirm-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    padding-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    z-index:700;
    font-size: 20px;
  }
  
  .confirm-modal-content{
    width: 50vw;
    height: 30vh;
    background-color: #ffffff;
    border-radius: 4px;
  }

  .confirm-modal-header, .confirm-modal-footer{
    padding: 10px;
  } 

  .confirm-modal-body{
    padding: 20px;
    border-top: 1px solid #eeeeee;
  }

  .confirm-modal-button{
    background-color:#ffffff;
    color: #000000;
    min-width: 25%;
    border:1px solid rgb(0, 0, 0);
    border-radius:10px;
    box-shadow: 0px 0px 2px 2px rgb(172, 172, 172);
    margin:0 30px 0 30px;
    font-size: 16px;
  }

  .confirm-modal-button:hover{
    background-color:#000000;
    color: #ffffff;
  }