.modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  z-index: 500;
}

.modal-content {
  width: 80vw;
  height: 85%;
  background-color: #ffffff;
  border-radius: 4px;
  /* overflow-y: auto; */
}

.modald-content {
  width: 1100px;
  height: auto;
  background-color: #ffffff;
  border-radius: 20px;
}

.modal-header,
.modal-footer {
  padding: 10px;
}

.modal-title {
  margin: 0;
}

.modald-body {
  padding: 10px;
  /* border-top: 1px solid #eeeeee; */
}

.modal-body {
  padding: 10px;
  border-top: 1px solid #eeeeee;
  display: grid;
  grid-template-columns: 50% 50%;
}

.modal-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.textarea-modal-input {
  width: 100%;
  height: 120px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-label {
  display: flex;
  font-size: 14px;
}

.modal-button {
  background-color: #ffffff;
  color: #000000;
  width: 15%;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(172, 172, 172);
  margin: 0 80px 0 80px;
  font-size: 16px;
}

.modal-button:hover {
  outline-color: transparent;
  outline-style: solid;
  background-color: #c5c5c5;
  box-shadow: 0 0 0 2px #8d8d8d;
}

.searchlistResult {
  width: auto;
  height: 75vh;
  overflow-y: none;
  border: 1px solid rgb(255, 255, 255);
  padding: 10px 10px 0px 10px;
}
