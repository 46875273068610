table {
  margin: auto;
  width: 80%;
}
thead {
  /* background-color: black; */
  color: white;
  font-weight: bold;
}
td {
  text-align: left;
}

tbody > tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.2);
}

.checkbox-rajang {
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 12pt;
}

/* @media screen and (max-height: 768px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 4px 4px;
    overflow-wrap: break-word;
  }
}

@media screen and (min-height: 769px && max-height: 1080px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 10px 10px;
    overflow-wrap: break-word;
  }
} */

.fixed-options .ant-select-dropdown {
  position: fixed !important;
}

.column-header {
  cursor: pointer;
  overflow: hidden;
}

.column-header:hover {
  color: grey;
}

.button-2 {
  /* background-color: rgba(51, 51, 51, 0.05); */
  background-color: transparent;
  border-radius: 8px;
  border-width: 0;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  list-style: none;
  margin: 0;
  padding: 2px 2px;
  text-align: center;
  /* transition: all 200ms; */
  vertical-align: top;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
