.datalist{
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    text-align:left
}

.datalist:hover{
    background-color: rgb(0, 0, 0);
    color: white;
    cursor: pointer;
}